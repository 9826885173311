import { Box, Text, Stack, HStack, Badge, Image } from "@chakra-ui/react";

export function TrainingActivity(props) {

  let statusColor;
  let statusVariant;
  let text;
  switch (props.trainingStatus) {
    case 'BOOKED':
      text = 'RESERVADA';
      statusColor = 'blue'; // Green color for ACTIVO status
      statusVariant = 'outline';
      break;
    case 'CANCELLED':
      text = 'CANCELADA';
      statusColor = 'red'; // Red color for CANCELADO status
      statusVariant = 'outline';
      break;
    case 'COMPLETED':
      text = 'COMPLETADA';
      statusColor = 'blue'; // Orange color for EXPIRADO status
      statusVariant = 'solid';
      break;
    case 'NO_SHOW':
      text = 'NO SHOW';
      statusColor = 'red'; // Blue color for PENDIENTE status
      statusVariant = 'solid';
      break;
    default:
      statusColor = '#000000'; // Default black color for unknown status
      statusVariant = 'solid';
  }

  const dateOptions = { year: "2-digit", month: "2-digit", day: "2-digit" };
  const timeOptions = { hour: "2-digit", minute: "2-digit" };

  function getDate() {
    if(props.trainingDate === undefined) return ("--:--");
    let date = new Date(props.trainingDate);
    return new Intl.DateTimeFormat("es-ES", dateOptions).format(date);
  }

  function getTime() {
    if(props.trainingTime === undefined) return ("--:--");
    let date = new Date(props.trainingTime);
    return new Intl.DateTimeFormat("es-ES", timeOptions).format(date);
  }

  return (
    <>
      <Box m="auto" w="100%">
        <HStack
          m="auto"
          role={"group"}
          p={4}
          bg="gray.800"
          color="white"
          spacing={5}
          w="100%"
          //my={{ base: 2, sm: 2, md: 3 }}
          cursor={"pointer"}
        >
          <Image
            className="bono-purchased-img"
            border="1px solid white"
            src={props.trainingImage}
            alt={props.trainingName + " logo"}
          />

          <Stack m="auto" ml={0} textAlign={"left"}>
            <Text
              fontFamily={"Oswald"}
              fontWeight="500"
              fontSize="2xl"
              color="white"
            >
              {props.trainingName}
            </Text>
            <Badge
              variant={statusVariant}
              colorScheme={statusColor}
              width={"fit-content"}
              borderRadius={0}
            >
              {text}
            </Badge>
            <Stack fontWeight={400} fontSize={"sm"} spacing={-0.5}>
              <Text>Hora: <b>{getTime()}h</b></Text>
              <Text>Fecha: <b>{getDate()}</b></Text>
            </Stack>
          </Stack>
        </HStack>
      </Box>
    </>
  );
}
