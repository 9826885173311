import {
  Stack,
  Heading,
  Box,
  Th,
  Table,
  Tfoot,
  Tr,
  Td,
  Thead,
  Tbody,
  TableContainer,
  HStack,
  Divider,
  Grid,
  GridItem,
  Spacer,
  useToast,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Checkbox,
  Button,
  Show,
  Hide,
  //Tooltip,
} from "@chakra-ui/react";
import "./style.css";
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect } from "react";
import axios from "axios";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { HiOutlineRefresh } from "react-icons/hi";
import { useTranslation } from "react-i18next";
//import { IoInformationCircleOutline } from "react-icons/io5";
//import { Stat, StatLabel, StatNumber, StatHelpText, StatArrow, StatGroup } from "@chakra-ui/react";

export function AdminStats() {
  const start_year = 2024; // year when the first data is available
  const selected_month = new Date().getMonth() + 1; // selected month in the dropdown menu
  const selected_year = new Date().getFullYear(); // selected year in the dropdown menu

  const [data, setData] = useState({ bonoSales: [], bonoTypeSales: [] });
  const [month, setMonth] = useState(selected_month);
  const [year, setYear] = useState(selected_year);
  const [refresh, setRefresh] = useState(false);
  const [detail, setDetail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();

  const monthsList = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      //setData({ bonoSales: [], bonoTypeSales: [] });
      console.log("Fetching data from API...");
      // Fetch data from API
      await axios
        .get("/api/admin/bono/sales/" + year)
        .then((response) => {
          setData(response.data);
          console.log(response.data);

        })
        .catch((error) => {
          toast({
            title: "¡Error inesperado!",
            description: error.message,
            status: "error",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });
        });

        setIsLoading(false);
    })();
  }, [toast, year, refresh]);

  function formatDataToSeries(value) {
    return value
      .flatMap((monthlyData) => monthlyData.sales) // Aplanamos la lista de ventas
      .reduce((acc, current) => {
        // Buscamos si ya existe una serie para este bonoName
        let serie = acc.find((s) => s.name === current.bonoName);
        if (!serie) {
          // Si no existe, la creamos
          serie = { name: current.bonoName, data: [] };
          acc.push(serie);
        }
        // Añadimos el total al array de data
        serie.data.push(current.total);
        return acc;
      }, []);
  }

  function formatDataToSeriesTotal(value) {
    const result = { data: [], name: "Total" };

    value.forEach((monthData) => {
      const totalSales = monthData.sales.reduce(
        (sum, sale) => sum + sale.total,
        0
      );
      result.data.push(totalSales);
    });

    return result;
  }

  const first_chart = {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false, // disables the credits (highcharts.com)
    },
    title: {
      text: "", //"Ingresos 2º semestre bonos julio 2023",
      align: "center",
    },
    xAxis: {
      categories: monthsList,
      crosshair: true,
      accessibility: {
        description: "Facturación anual total y por tipo de bono",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "", //"Moneda: Euros (€)",
      },
    },
    tooltip: {
      valueSuffix: " €",
    },
    legend: {
      enabled: detail,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: data
      ? detail
        ? formatDataToSeries(data.bonoSales)
        : formatDataToSeriesTotal(data.bonoSales)
      : [],
  };

  const second_chart = {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false, // disables the credits (highcharts.com)
    },
    title: {
      text: "", //"Ingresos 2º semestre bonos julio 2023",
      align: "center",
    },
    xAxis: {
      categories:
        data.bonoSales && data.bonoSales.length > 0
          ? data.bonoSales
              .find((item) => item.month === month)
              .sales.map((item) => item.bonoName)
          : [],
      //crosshair: true,
      accessibility: {
        description: "Facturación de los bonos para el mes y año indicados",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "", //"Moneda: Euros (€)",
      },
    },
    tooltip: {
      valueSuffix: " €",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        showInLegend: false,
        name: "Facturación",
        data:
          data.bonoSales && data.bonoSales.length > 0
            ? data.bonoSales
                .find((item) => item.month === month)
                .sales.map((item) => item.total)
            : [],
      },
    ],
  };

  return (
    <>
      <HStack
        display="flex"
        justifyContent="space-between"
        width={{ base: "90%", sm: "90%", md: "80%" }}
        margin="auto"
        maxW="1300px"
        pb={4}
      >
        {/* Button can be displayed on the left hand side if located here and spacer can be deleted*/}
        <Heading fontSize="lg" textAlign={"left"} fontWeight={400}>
          Facturación anual
        </Heading>

        <Spacer m="auto">
          <Checkbox
            size="sm"
            fontWeight={400}
            defaultChecked
            onChange={() => setDetail(!detail)}
            pt={0.5}
          >
            Detalle
          </Checkbox>
        </Spacer>

        {
          <Menu placement="bottom-end">
            <MenuButton size="sm" mr={-1}>
              {year}&nbsp;
              <ChevronDownIcon />
            </MenuButton>
            <MenuList borderRadius={0} minW="80px" mr={0}>
              {Array.from(
                { length: selected_year - start_year + 1 },
                (_, i) => start_year + i
              ).map((year) => (
                <MenuItem
                  key={year}
                  onClick={() => {
                    setYear(year);
                    if (new Date(year, month - 1) > new Date()) {
                      // avoids selecting a month in the future
                      setMonth(new Date().getMonth() + 1);
                    }
                  }}
                >
                  {year}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        }
        <Button
          title="Refrescar página"
          variant="ghost"
          colorScheme="black"
          size={{ base: "sm", sm: "sm", md: "md" }}
          onClick={() => setRefresh(!refresh)}
          isLoading={isLoading}
          px={"0px !important"}
          mr={-2}
        >
          <HiOutlineRefresh size="1.25em" />
        </Button>
      </HStack>
      <Stack
        width={{ base: "90%", sm: "90%", md: "80%" }}
        margin="auto"
        maxW="1300px"
        spacing={5}
        mb={4}
      >
        {/*<Box>
          <StatGroup spacing={4}>
            <Stat>
              <StatLabel>Ventas</StatLabel>
              <StatNumber>2350,67€</StatNumber>
              <StatHelpText>
                <StatArrow type="increase" />
                23.36%
              </StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Reservas</StatLabel>
              <StatNumber>125</StatNumber>
              <StatHelpText>
                <StatArrow type="decrease" />
                9.05%
              </StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Bonos</StatLabel>
              <StatNumber>85</StatNumber>
              <StatHelpText>
                <StatArrow type="decrease" />
                9.05%
              </StatHelpText>
            </Stat>
          </StatGroup>
        </Box>*/}

        <Box mt={2}>
          <HighchartsReact highcharts={Highcharts} options={first_chart} />
        </Box>
        <Divider />

        <HStack display="flex" justifyContent="space-between" mt={3}>
          {/* Button can be displayed on the left hand side if located here and spacer can be deleted*/}
          <Heading fontSize="lg" textAlign={"left"} fontWeight={400}>
            Facturación mensual
          </Heading>
          <Spacer />

          <HStack spacing={4}>
            <Menu placement="bottom-end">
              <MenuButton size="sm">
                {monthsList[month - 1]}&nbsp;
                <ChevronDownIcon />
              </MenuButton>
              <MenuList borderRadius={0} minW="130px">
                {monthsList.map((monthName, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => setMonth(index + 1)}
                    isDisabled={!(new Date() >= new Date(year, index))}
                  >
                    {monthName}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </HStack>
        </HStack>

        <TableContainer px={2}>
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                <Th pt={0} px={0}>
                  <Show below="sm">M. DE PAGO </Show>
                  <Hide below="sm">MÉTODO DE PAGO</Hide>
                </Th>
                <Th pt={0} px={0} textAlign="center">
                  VENTAS
                </Th>
                <Th pt={0} px={0} isNumeric>
                  TOTAL
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.bonoTypeSales.length > 0 &&
                data.bonoTypeSales
                  .find((item) => item.month === month)
                  .sales.map((item) => {
                    if (
                      item.paymentStatus === "OUTSTANDING" &&
                      item.total === 0
                    )
                      return null;
                    return (
                      <Tr
                        key={item.paymentStatus}
                        color={item.paymentStatus === "OUTSTANDING" && "red"}
                        fontWeight={
                          item.paymentStatus === "OUTSTANDING" && "500"
                        }
                      >
                        <Td cursor="default" py={3} px={0} w="45%">
                          {t(item.paymentStatus)}
                        </Td>
                        <Td py={3} px={0} textAlign="center">
                          {item.sales}
                        </Td>
                        <Td py={3} px={0} isNumeric w="45%">
                          {item.total.toFixed(2)} €
                        </Td>
                      </Tr>
                    );
                  })}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th fontSize="md" px={0}>
                  TOTAL
                </Th>
                <Th></Th>
                <Th fontSize="md" px={0} isNumeric>
                  {data.bonoTypeSales.length > 0 &&
                    data.bonoTypeSales
                      .find((item) => item.month === month)
                      .sales.reduce((sum, current) => sum + current.total, 0)
                      .toFixed(2)}{" "}
                  €
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>

        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(6, 1fr)"
          gap={{ base: 4, sm: 4, md: 8 }}
          mt={3}
        >
          <GridItem colSpan={{ base: 6, sm: 6, md: 6, lg: 3, xl: 2 }}>
            <Box px={{ base: 2, sm: 2, md: 2, lg: 0 }}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th pt={0} px={0}>
                        <Show below="sm">T. DE BONO </Show>
                        <Hide below="sm">TIPO DE BONO</Hide>
                      </Th>
                      <Th pt={0} px={0} textAlign="center">
                        VENTAS
                      </Th>
                      <Th pt={0} px={0} isNumeric>
                        TOTAL
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.bonoSales.length > 0 &&
                      data.bonoSales
                        .find((item) => item.month === month)
                        .sales.map((item) => {
                          return (
                            <Tr key={item.bonoName}>
                              <Td cursor="default" py={3} px={0} w="45%">
                                {item.bonoName}
                              </Td>
                              <Td py={3} px={0} textAlign="center">
                                {item.numOfSales}
                              </Td>
                              <Td py={3} px={0} isNumeric w="45%">
                                {item.total.toFixed(2)} €
                              </Td>
                            </Tr>
                          );
                        })}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th fontSize="md" px={0}>
                        TOTAL
                      </Th>
                      <Th></Th>
                      <Th fontSize="md" px={0} isNumeric>
                        {data.bonoSales.length > 0 &&
                          data.bonoSales
                            .find((item) => item.month === month)
                            .sales.reduce(
                              (sum, current) => sum + current.total,
                              0
                            )
                            .toFixed(2)}{" "}
                        €
                      </Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 6, sm: 6, md: 6, lg: 3, xl: 4 }}>
            <Box id="chart-height">
              <HighchartsReact highcharts={Highcharts} options={second_chart} />
            </Box>
          </GridItem>
        </Grid>
      </Stack>
    </>
  );
}
