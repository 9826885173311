import {
  Box,
  Stack,
  useMediaQuery,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Select,
  Spacer,
  useToast,
  Avatar,
  AvatarGroup,
  Spinner,
  Checkbox,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Badge,
  NumberInput,
  NumberInputField,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputStepper,
} from "@chakra-ui/react";
import "./style.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import React, { useEffect, useState, useRef, memo } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import {
  MdOutlineEventRepeat,
  MdOutlineEventAvailable,
  MdOutlinePublish,
  MdOutlineEventBusy,
  MdOutlineSearch,
  MdClose,
} from /*MdOutlinePersonRemove,*/ "react-icons/md";
import ConfirmationModal from "../ConfirmationModal";
import { useBreakpointValue } from "@chakra-ui/react";
//import { FormErrorMessage } from "@chakra-ui/react";

// DEMO: https://github.com/fullcalendar/fullcalendar-examples/blob/main/react/src/DemoApp.jsx
// DEMO VÁLIDA: https://codesandbox.io/s/fullcalendar-react-draggable-forked-ehr0h?file=/src/App.js:2139-2157
// REACT MODAL DIALOGS: https://around25.com/blog/how-to-delegate-your-react-dialogs/
// https://github.com/Around25/delegate-react-dialogs/tree/master/src
// using react hook form for the forms: https://react-hook-form.com/get-started

var id = 0;
const toDelete = []; // events to delete from database
/*const coachesList = [
  { defaultCoachName: "Juan Carlos Bujosa", defaultCoachId: 1 },
];*/

export function AdminScheduler({ sessionsList, coachesList }) {
  // Contains de current events in the calendar
  const [state, setState] = useState({
    externalEvents: sessionsList,
    calendarEvents: [], // Current events in the calendar
  });

  const [refresh, setRefresh] = useState(true);
  const toast = useToast(); // to display toasts
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]); // all users available to add to the training
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // to show the confirmation modal in case of changes in a booked published event

  // Makes the calendar responsive
  const [isMobilePhone] = useMediaQuery("(max-width: 800px)");
  const [activeView, setActiveView] = useState("timeGridDay");
  const calendarRef = React.useRef(null);

  React.useEffect(() => {
    // Responsiveness
    //console.log("View Changed", activeView);
    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom.getApi() : null;
    API && API.changeView(activeView);
  }, [activeView]);

  if (isMobilePhone && activeView !== "timeGridDay") {
    setActiveView("timeGridDay");
  }

  if (!isMobilePhone && activeView !== "timeGridWeek") {
    setActiveView("timeGridWeek");
  }

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  useEffect(() => {
    (async () => {
      console.log("Obteniendo usuarios");
      const { data } = await axios.get("/api/admin/users/bono/active");
      setUsers(data);
    })();
  }, []);

  useEffect(() => {
    setLoading(true);
    const monday = getMonday(
      calendarRef.current.calendar.currentData.currentDate
    );
    const from = new Date(monday);
    from.setMonth(from.getMonth() - 1);
    const to = new Date(from);
    to.setMonth(to.getMonth() + 3);

    console.log("from", from);
    console.log("to", to);

    (async () => {
      const body = {
        from: new Date(from), // La fecha "from" en formato de fecha (date)
        to: new Date(to), // La fecha "to" en formato de fecha (date)
      };

      const { data } = await axios
        .post("/api/admin/trainings", body)
        .catch((error) => {
          toast({
            title: "¡Error inesperado!",
            description: error.message,
            status: "error",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });
        });

      const events = data.map((item) => ({
        id: getNewID(),
        title: item.trainingName,
        color: sessionsList.filter(
          (event) => event.trainingTypeId === item.trainingTypeId
        )[0].color,
        start: item.startDate,
        end: item.endDate,
        visible: item.visible,
        extendedProps: {
          trainingId: item.id,
          trainingTypeId: item.trainingTypeId,
          defaultCoachName: item.coachName, // to get the first name of the coach
          defaultCoachId: item.coachId,
          defaultMaxSpots: item.maxSpots,
          defaultblockedSpots: item.blockedSpots,
        },
      }));

      setState((prevState) => ({
        ...prevState, // Copiar el estado anterior
        calendarEvents: [...events], // Agregar el nuevo evento
      }));

      //console.log(state.calendarEvents);

      setLoading(false);
      //console.log("Entrenamientos calendario", events);
    })();
  }, [refresh, toast, sessionsList]);

  // Returns a new event id
  function getNewID() {
    return id++;
  }

  // Event text
  function renderEventContent(eventInfo) {
    return (
      <>
        <Box
          fontSize="xs"
          /* textDecorationLine="line-through"*/
          borderTop={
            eventInfo.event.extendedProps.trainingId &&
            eventInfo.event.extendedProps.visible
              ? "solid 2px red"
              : null
          }
          borderStyle={"double"}
          boxShadow={
            eventInfo.event.extendedProps.edited
              ? "0 2px 0 orange inset;"
              : null
          }
        >
          <Text fontWeight={400}>{eventInfo.timeText}</Text>
          <HStack>
            <Text>
              {eventInfo.event.title} -{" "}
              {eventInfo.event.extendedProps.defaultCoachName &&
                eventInfo.event.extendedProps.defaultCoachName.split(" ").pop()}
            </Text>
          </HStack>
        </Box>
      </>
    );
  }

  // Creating a new event
  function handleDateSelect(selectInfo) {
    // TODO: this will need to be done at some point to avoid event to dissapear once using the modal
    /*const newEvent = {
      id: getNewID(),
      title: "",
      color: "#4299e1",
      start: selectInfo.startStr,
      end: new Date(new Date(selectInfo.startStr).getHours() + 2)
        .toISOString()
        .slice(0, 19),
      extendedProps: "",
      visible: false,
      edited: true,
    };

    console.log("new event to add", newEvent);

    setState((state) => {
      return {
        ...state,
        calendarEvents: state.calendarEvents.concat(newEvent),
      };
    });*/

    setEditEvent(selectInfo);
  }

  // Editing current event
  function handleEventClick(clickInfo) {
    setEditEvent(clickInfo.event);
  }

  // TODO: modify backend to add one hour by default when enddate is not settled, or at least, reject the saving in db
  const handleEventReceive = (eventInfo) => {
    let startDate = new Date(eventInfo.event.startStr);
    let endDate = new Date(startDate.setHours(startDate.getHours() + 1));

    let formattedEndDate =
      endDate.getFullYear() +
      "-" +
      ("0" + (endDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + endDate.getDate()).slice(-2) +
      "T" +
      ("0" + endDate.getHours()).slice(-2) +
      ":" +
      ("0" + endDate.getMinutes()).slice(-2) +
      ":" +
      ("0" + endDate.getSeconds()).slice(-2);

    const newEvent = {
      id: getNewID(),
      title: eventInfo.event.title,
      color: eventInfo.event.backgroundColor,
      start: eventInfo.event.startStr,
      end: formattedEndDate,
      extendedProps: eventInfo.event.extendedProps,
      visible: false,
      edited: true,
    };

    console.log("new event to add", newEvent);

    setState((state) => {
      return {
        ...state,
        calendarEvents: state.calendarEvents.concat(newEvent),
      };
    });
  };

  function handleEventChange(changeInfo) {
    //console.log("event changed", changeInfo);
    // updates event start and end times
    setState((prevState) => ({
      ...prevState,
      calendarEvents: prevState.calendarEvents.map((event) => {
        if (event.id === parseInt(changeInfo.event.id)) {
          return {
            ...event,
            start: changeInfo.event.startStr,
            end: changeInfo.event.endStr,
            edited: true,
          };
        }
        return event;
      }),
    }));
  }

  function handleModal(e) {
    var newCalendar = state.calendarEvents;
    console.log("eeeh");

    if (e.remove) {
      console.log("Event to delete", e.event);

      // Event not saved in database
      if (!e.event.extendedProps.trainingId) {
        newCalendar = newCalendar.filter(
          (event) => event.id !== parseInt(e.event.id)
        );
        setState({
          externalEvents: sessionsList,
          calendarEvents: newCalendar,
        }); // rerenders the calendar
        setEditEvent(false); // to close the modal
        return;
      }

      // Event not visible in database (not published) can be deleted
      //if (!e.event.extendedProps.visible) {
      toDelete.push(e.event.extendedProps.trainingId); // added to the to delete list
      newCalendar = newCalendar.filter(
        (event) => event.id !== parseInt(e.event.id)
      );
      setState({
        externalEvents: sessionsList,
        calendarEvents: newCalendar,
      });
      setEditEvent(false);
      return;
      //}
    } else {
      // Otherwise, it is replaced for the new event
      console.log("Saved event", e);

      const newEvent = {
        id: getNewID(),
        title: sessionsList.filter(
          (event) => event.trainingTypeId === parseInt(e.trainingTypeId)
        )[0].title,
        color: sessionsList.filter(
          (event) => event.trainingTypeId === parseInt(e.trainingTypeId)
        )[0].color,
        visible: e.visible,
        edited: true,
        extendedProps: {
          defaultCoachName: coachesList.filter(
            (coach) => coach.coachId === parseInt(e.defaultCoachId)
          )[0].coachName,
          defaultCoachId: coachesList.filter(
            (coach) => coach.coachId === parseInt(e.defaultCoachId)
          )[0].coachId,
          trainingTypeId: e.trainingTypeId,
          trainingId: e.trainingId,
          defaultMaxSpots: e.maxCap,
          defaultblockedSpots: e.blockedSpots,
        },
        start: e.dateStart,
        end: e.dateEnd,
      };

      // Removes the previous event
      newCalendar = newCalendar.filter(
        (event) => event.id !== parseInt(e.prevId)
      );

      // Adds the new edited event
      newCalendar = newCalendar.concat(newEvent);
    }

    // Finally the calendar is rerendered
    setState({ externalEvents: sessionsList, calendarEvents: newCalendar });
    setEditEvent(false); // to close the modal
  }

  // Makes all the events visible to the users
  async function publishEvents(e) {
    console.log("POSTING EVENTS", e);
    // Publish events endpoint call
    setLoading(true);

    const from = new Date(e.fromP);
    const to = new Date(e.toP);

    const toPublish = state.calendarEvents
      .filter((item) => {
        const startDate = new Date(item.start);
        return startDate >= from && startDate <= to;
      })
      .map((item) => item.extendedProps.trainingId);

    console.log("Eventos a publicar:", toPublish);

    await axios.post("api/admin/trainings/publish", toPublish);

    toast({
      title: "¡Entrenamientos publicados!",
      description: "Ya son visibles para todos los usuarios",
      position: "bottom-right",
      duration: 3000,
      isClosable: true,
    });

    setRefresh(!refresh); // calls the endpoint and refreshes the calendar*/
  }

  // This only saves the events in database but does not make them visible
  function showModal(response) {
    setShowConfirmationModal(false);

    if (response === null) {
      return;
    }

    saveEvents();
  }

  async function saveEvents() {
    // Save events endpoint call
    setLoading(true);

    if (toDelete.length > 0) {
      console.log("Events to delete", toDelete);
      await axios.put("/api/admin/trainings/delete", toDelete);
      toDelete.length = 0; // empties the array
    }

    // gets only the events that have been edited:
    const events = state.calendarEvents
      .filter((event) => event.edited)
      .map((item) => ({
        trainingId: item.extendedProps.trainingId
          ? item.extendedProps.trainingId
          : "",
        startDate: item.start,
        endDate: item.end,
        trainingTypeId: item.extendedProps.trainingTypeId,
        coachId: item.extendedProps.defaultCoachId,
        location: "Sala Principal",
        visible: item.visible,
        maxSpots: item.extendedProps.defaultMaxSpots,
        blockedSpots: item.extendedProps.defaultblockedSpots,
        //edited: item.edited ? item.edited : false,
      }));

    console.log("Events to save", events);

    await axios.put("/api/admin/trainings/save", events);

    toast({
      title: "¡Entrenamientos guardados!",
      description: "Recuerda publicarlos para que sean visibles",
      position: "bottom-right",
      duration: 3000,
      isClosable: true,
    });

    setRefresh(!refresh); // calls the endpoint and refreshes the calendar
  }

  function repeatWeek() {
    console.log("Repeating week...");

    // Getting previous week
    const to = calendarRef.current.getApi().view.currentStart;

    const from = new Date(to);
    from.setDate(to.getDate() - 7);

    const prevWeek = state.calendarEvents.filter((event) => {
      const eventStartDate = new Date(event.start); // Convert event start to Date object
      return eventStartDate >= from && eventStartDate <= to;
    });

    const newWeek = prevWeek.map((item) => ({
      ...item,
      id: getNewID(),
      visible: false,
      extendedProps: { ...item.extendedProps, trainingId: null },
      edited: true,
      start: formatDateToEuropeMadridTimezone(
        new Date(
          new Date(item.start).setDate(new Date(item.start).getDate() + 7)
        )
      ),
      end: formatDateToEuropeMadridTimezone(
        new Date(new Date(item.end).setDate(new Date(item.end).getDate() + 7))
      ),
    }));

    const newCalendar = {
      ...state,
      externalEvents: sessionsList,
      calendarEvents: [...state.calendarEvents, ...newWeek],
    };

    setState(newCalendar);
  }

  function formatDateToEuropeMadridTimezone(date) {
    const utcDate = new Date(date);

    // Calculamos el desplazamiento horario en minutos para Europa/Madrid (GMT+2)
    const timeZoneOffsetMinutes = 2 * 60;
    const europeMadridDate = new Date(
      utcDate.getTime() + timeZoneOffsetMinutes * 60 * 1000
    );

    return europeMadridDate.toISOString().slice(0, -5);
  }

  const [editEvent, setEditEvent] = useState(false); // edit event modal
  const [publishedEvents, setPublishedEvents] = useState(false); // edit event modal

  function handlePublishModal(e) {
    if (publishedEvents) {
      publishEvents(e);
    }

    setPublishedEvents(!publishedEvents);
  }

  return (
    <>
      <ModalEvent
        isOpen={editEvent}
        onClose={editEvent}
        users={users}
        onAction={handleModal}
        returnFocusOnClose={false}
      />
      <ModalPublish
        isOpen={publishedEvents}
        onClose={publishedEvents}
        onAction={handlePublishModal}
        returnFocusOnClose={false}
      />
      {showConfirmationModal && (
        <ConfirmationModal
          title="Confirma el guardado"
          message="Si modificas una sesión que ya había sido reservada, los participantes serán notificados de los cambios en la reserva. ¿Estás seguro de que deseas continuar? "
          yesButton="Aceptar"
          optionalButton="Cancelar"
          onConfirm={showModal}
        />
      )}

      <Box
        width={{ base: "90%", sm: "90%", md: "80%" }}
        margin="auto"
        mb={4}
        maxW="1300px"
      >
        <HStack pb={4} display="flex" justifyContent="space-between">
          <HStack fontSize={{ base: "xs", sm: "md" }} mt={0.5}>
            <Text
              width="fit-content"
              color="black"
              fontWeight={400}
              borderTop="2px solid red"
            >
              PUBLICADO
            </Text>
            <Text
              width="fit-content"
              color="black"
              fontWeight={400}
              borderTop="2px solid orange"
            >
              EDITADO
            </Text>
          </HStack>
          <HStack spacing={-3} mr={-3.5}>
            <Button
              title="Repetir semana anterior"
              colorScheme="black"
              onClick={repeatWeek}
              size={{ base: "sm", sm: "sm", md: "md" }}
              isLoading={loading}
              variant="ghost"
            >
              <MdOutlineEventRepeat size="1.25em" />
            </Button>
            <Button
              title="Publicar eventos"
              variant="ghost"
              colorScheme="red"
              onClick={handlePublishModal}
              size={{ base: "sm", sm: "sm", md: "md" }}
              isLoading={loading}
              _hover={{ bg: "transparent" }}
              /* Publish events button */
            >
              <MdOutlinePublish size="1.25em" />
            </Button>
            <Button
              title="Guardar eventos"
              variant="ghost"
              colorScheme="black"
              onClick={() => {
                if (
                  state.calendarEvents.filter(
                    (event) => event.edited && event.visible
                  ).length > 0
                ) {
                  setShowConfirmationModal(true);
                } else {
                  saveEvents();
                }
              }}
              size={{ base: "sm", sm: "sm", md: "md" }}
              isLoading={loading}
              /* Save events button */
            >
              <MdOutlineEventAvailable size="1.25em" />
            </Button>
          </HStack>
        </HStack>
        <Box
          id="external-events"
          spacing={1}
          m={"auto"}
          p={{ base: 4, sm: 4, md: 6 }}
          px={2}
          mb={6}
          border="1px solid #ddd"
        >
          <Text mb={3} fontWeight={400}>
            Arrastra una sesión:
          </Text>
          {state.externalEvents
            .filter((event) => event.active)
            .map((event) => (
              <ExternalEvent key={event.trainingTypeId} event={event} />
            ))}
        </Box>

        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={activeView} //"dayGridWeek", timeGridWeek
          events={state.calendarEvents}
          ref={calendarRef} // to make it responsive
          weekends={false} // disables weekend visibility
          allDaySlot={false} // disables all day row
          stickyHeaderDates={false}
          timeZone={"Europe/Madrid"}
          views={{
            // custom settings for each view
            timeGridWeek: {
              // name of view
              titleFormat: { year: "numeric", month: "long", day: "numeric" },
              // other view-specific options here
            },
            timeGridDay: {
              // name of view
              titleFormat: { month: "long", day: "numeric" },
              // other view-specific options here
            },
          }}
          editable={!loading}
          selectable={!loading}
          selectMirror
          droppable
          dayMaxEvents // collapses when no more events fit in the day
          nowIndicator // current time bar
          eventResizableFromStart // allows resizing from the starting edge
          height={"auto"}
          windowResizeDelay={0} // no delay when resizing the window
          select={handleDateSelect} // on date click
          eventReceive={handleEventReceive} // adds a new event on the calendar from an external event (drag)
          eventContent={renderEventContent} // renders the event text on change
          eventClick={handleEventClick} // on event click
          eventChange={handleEventChange} // on event resizing and on event change date
          //eventResize={handleEventResize} // on event resized --> not necessary, as eventChange also handles resizing
          locale={"es"} // sets spanish as the calendar's language
          buttonText={{ today: "hoy" }} // changes the default button text
          slotMinTime={"08:00:00"} // minimum time displayed
          slotMaxTime={"21:00:00"} // maximum time displayed
          //slotDuration={'00:15:00'}
        />
      </Box>
    </>
  );

  // ****** MODAL EVENT ******
  function ModalEvent({ isOpen, onAction, users }) {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isConfirmDevModalOpen, setIsConfirmDevModalOpen] = useState(false);
    const [participantsRefresh, setParticipantsRefresh] = useState(false);
    const [trainingParticipants, setTrainingParticipants] = useState(null); // Traininig participants
    //const [addedParticipants, setAddedParticipants] = useState([]); // New training participants
    const [editingParticipants, setEditingParticipants] = useState(false); // Editing participants collapsa
    const [isInputFocused, setInputFocused] = useState(false);
    const [search, setSearch] = useState(users); // Search results
    const [participants, setParticipants] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const maxAvatars = useBreakpointValue({ base: 6, sm: 8, md: 10 });

    const {
      control,
      handleSubmit,
      register: eventRegister,
      reset,
      getValues,
      formState: { dirtyFields, errors },
    } = useForm({
      defaultValues: {
        prevId: editEvent.id ? editEvent.id : null,
        visible:
          editEvent.extendedProps !== undefined
            ? editEvent.extendedProps.visible
            : false,
        session: editEvent.title,
        defaultCoachId:
          editEvent.extendedProps !== undefined
            ? editEvent.extendedProps.defaultCoachId
            : null,
        trainingId:
          editEvent.extendedProps !== undefined
            ? editEvent.extendedProps.trainingId
            : null,
        trainingTypeId:
          editEvent.extendedProps !== undefined
            ? editEvent.extendedProps.trainingTypeId
            : null,
        dateStart: editEvent.startStr,
        dateEnd: editEvent.endStr,
        maxCap:
          editEvent.extendedProps !== undefined
            ? editEvent.extendedProps.defaultMaxSpots
            : null,
        blockedSpots:
          editEvent.extendedProps !== undefined
            ? editEvent.extendedProps.defaultblockedSpots
            : null,
      },
      shouldFocusError: false,
    });

    function handleClose() {
      setEditEvent(false);
    }

    function handleDelete() {
      onAction({ remove: true, event: editEvent }); // Indicates which id needs to be removed
    }

    function onSubmit(values) {
      // If any other change has been made in the event structure
      if (
        dirtyFields["trainingTypeId"] ||
        dirtyFields["defaultCoachId"] ||
        dirtyFields["dateEnd"] ||
        dirtyFields["dateStart"] ||
        dirtyFields["maxCap"] ||
        dirtyFields["blockedSpots"]
      ) {
        onAction(values); // it is updated
      } else {
        handleClose(); // closes the modal
      }
    }

    function handleType(e) {
      const value = e.target.value;
      const filteredUsers = users.filter(
        (user) =>
          user.name.toLowerCase().includes(value.toLowerCase()) ||
          user.surname1.toLowerCase().includes(value.toLowerCase()) ||
          user.surname2.toLowerCase().includes(value.toLowerCase())
      );

      setSearch(filteredUsers);
    }

    function deleteInputValueMobile() {
      const inputElement = document.getElementById("search-input-mobile");
      inputElement.value = "";
      setSearch(users);
    }

    function handleAddParticipant(e) {
      const element = e.target.closest(".prevent-select");

      console.log("Elemento", element);

      if (element.getAttribute("data-user-selectable") === "false") return;

      console.log(
        "Añadir participante",
        element.id,
        "- JSON: ",
        element.getAttribute("data-user")
      );

      setEditingParticipants([
        JSON.parse(element.getAttribute("data-user")),
        ...editingParticipants,
      ]);
    }

    useEffect(() => {
      async function fetchData() {
        try {
          // Realiza la llamada a tu endpoint al abrir el modal
          const { data } = await axios.get(
            "/api/admin/bookings/training/" + editEvent.extendedProps.trainingId
          );
          setTrainingParticipants(data);
        } catch (error) {
          console.error("Error al obtener datos de la API:", error);
        }
      }

      if (
        isOpen &&
        editEvent.extendedProps &&
        editEvent.extendedProps.visible
      ) {
        fetchData(); // calls the endpoint to get the participants
      } else {
        setTrainingParticipants([]);
      }
    }, [isOpen, participantsRefresh]);

    function handleUpdateParticipants() {
      const participants = getValues().participantes;

      if (participants === undefined) {
        setEditingParticipants(false); // participants edition is closed
        reset();
        return;
      }

      // Gets the added participants
      const addedParticipants = Object.keys(participants)
        .filter(
          (key) =>
            participants[key] === true &&
            !trainingParticipants.map((tp) => tp.id).includes(parseInt(key)) // if they were already added, no need to add them again
        )
        .map((id) => parseInt(id));

      // Gets the removed participants
      const removedParticipants = Object.keys(participants)
        .filter(
          (key) =>
            participants[key] === false &&
            trainingParticipants.map((tp) => tp.id).includes(parseInt(key))
        ) // if they were already added, need to remove them
        .map((id) => parseInt(id));

      if (removedParticipants.length === 0 && addedParticipants.length === 0) {
        setEditingParticipants(false); // no hay cambios, cerramos la edición de participantes
        reset();
        return;
      }

      setParticipants({
        addedParticipants,
        removedParticipants,
      });

      if (removedParticipants.length > 0) {
        // Removal confirmation dialog
        setIsConfirmDevModalOpen(true); // abrimos el modal
      }

      if (addedParticipants.length > 0) {
        // Confirmation dialog
        setIsConfirmModalOpen(true); // abrimos el modal
      }
    }

    async function handleConfirmModal(response) {
      if (response === null) {
        reset(); // resets the form to its initial default values
        setIsConfirmModalOpen(false);
        setIsConfirmDevModalOpen(false);
        return; // opción de seguir editando
      }

      await updateTrainingBookings(response);
    }

    async function updateTrainingBookings(response) {
      setIsLoading(true);

      await axios
        .post(
          "/api/admin/booking/training/" + editEvent.extendedProps.trainingId,
          {
            bookings: {
              userIds: participants.addedParticipants,
            },
            cancellations: {
              returnSession: response,
              reason: "Empty reason for now",
              userIds: participants.removedParticipants,
            },
          }
        )
        .catch((error) => {
          toast({
            title: "¡Error inesperado!",
            description: error.message,
            status: "error",
            position: "bottom-right",
            duration: 3000,
            isClosable: true,
          });
        })
        .then((response) => {
          if (
            response.data.bookingErrors.length > 0 ||
            response.data.cancellationErrors.length > 0
          ) {
            toast({
              title: "¡Error inesperado!",
              description: "No se han podido realizar todos los cambios",
              status: "error",
              position: "bottom-right",
              duration: 3000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Lista de participantes actualizada 🥳",
              position: "bottom-right",
              duration: 3000,
              isClosable: true,
            });
            setTrainingParticipants(null); // shows the spinner
            setEditingParticipants(!editingParticipants); // closes participants' edition
            setParticipantsRefresh(!participantsRefresh); // refreshes the participants' list
          }
        });

      setIsLoading(false);
      setIsConfirmModalOpen(false); // closes the confirmation modal
      setIsConfirmDevModalOpen(false); // closes the confirmation modal
    }

    return (
      <>
        <Modal
          onClose={handleClose}
          isOpen={isOpen}
          isCentered
          size={{ base: "sm", sm: "sm", md: "md" }}
          borderRadius={"0px"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader mt={2} pb={2} textAlign={"left"}>
              {editEvent.title ? (
                <Text>Editar sesión</Text>
              ) : (
                <Text>Nueva sesión</Text>
              )}
            </ModalHeader>

            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                {isConfirmModalOpen && (
                  <ConfirmationModal
                    title="Confirmación"
                    message="¿Estás seguro que deseas continuar con la operación?"
                    yesButton="Sí, continuar"
                    optionalButton="Deshacer cambios"
                    onConfirm={handleConfirmModal}
                  />
                )}
                {isConfirmDevModalOpen && (
                  <ConfirmationModal
                    title="Confirmación y devolución"
                    message="Deshaz los cambios si no deseas continuar con la operación. ¿Quieres devolverles la sesión a los participantes eliminados?"
                    yesButton="Devolver"
                    noButton="No"
                    optionalButton="Deshacer cambios"
                    onConfirm={handleConfirmModal}
                  />
                )}
                <HStack>
                  <FormControl isInvalid={errors.trainingTypeId}>
                    <FormLabel htmlFor="trainingTypeId" fontSize="sm">
                      Sesión
                    </FormLabel>
                    <Select
                      id="trainingTypeId"
                      placeholder="Elige una sesión"
                      size="sm"
                      {...eventRegister("trainingTypeId", {
                        required: "Obligatorio",
                      })}
                    >
                      {sessionsList.map((session) => (
                        <option
                          key={session.trainingTypeId}
                          value={session.trainingTypeId}
                        >
                          {session.title} {!session.active && "(inactivo)"}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl isInvalid={errors.defaultCoachId}>
                    <FormLabel htmlFor="defaultCoachId" fontSize="sm">
                      Entrenador
                    </FormLabel>
                    <Select
                      id="defaultCoachId"
                      placeholder="Elige el coach"
                      size="sm"
                      {...eventRegister("defaultCoachId", {
                        required: "Obligatorio",
                      })}
                    >
                      {coachesList.map((coach) => (
                        <option key={coach.coachId} value={coach.coachId}>
                          {coach.coachName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </HStack>

                <HStack pt={4} max-width="336px">
                  <FormControl isInvalid={errors.maxCap} w="fit-content">
                    <FormLabel htmlFor="maxCap" fontSize="sm">
                      Capacidad máx.
                    </FormLabel>
                    <Controller
                      name="maxCap"
                      control={control}
                      rules={{ required: "Obligatorio" }}
                      defaultValue={parseInt(0) || 0} // Ensure a valid number is set as default
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <NumberInput
                          id="maxCap"
                          placeholder="Elige una capacidad máxima"
                          size="sm"
                          onBlur={onBlur} // Handle blur events
                          value={value || 0} // Ensure the value is not null or undefined
                          onChange={(valueAsString, valueAsNumber) =>
                            onChange(valueAsNumber)
                          } // Use the numeric value for onChange
                          //isDisabled={editEvent.extendedProps.visible}
                          min={0}
                          max={100}
                        >
                          <NumberInputField ref={ref} name={name} />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      )}
                    />
                  </FormControl>
                  <FormControl isInvalid={errors.maxCap} w="fit-content">
                    <FormLabel htmlFor="blockedSpots" fontSize="sm">
                      Plazas reservadas
                    </FormLabel>
                    <Controller
                      name="blockedSpots"
                      control={control}
                      //rules={{ required: "Obligatorio" }}
                      defaultValue={parseInt(0) || 0} // Ensure a valid number is set as default
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                      }) => (
                        <NumberInput
                          id="blockedSpots"
                          placeholder="Reserva una plaza"
                          size="sm"
                          onBlur={onBlur} // Handle blur events
                          value={value || 0} // Ensure the value is not null or undefined
                          onChange={(valueAsString, valueAsNumber) =>
                            onChange(valueAsNumber)
                          } // Use the numeric value for onChange
                          //isDisabled={editEvent.extendedProps.visible}
                          min={0}
                          max={100}
                        >
                          <NumberInputField ref={ref} name={name} />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      )}
                    />
                  </FormControl>
                </HStack>

                <HStack pt={4} max-width="336px">
                  <Box width={"48.5%"}>
                    <FormControl isInvalid={errors.dateStart}>
                      <FormLabel htmlFor="dateStart" fontSize="sm">
                        Fecha inicio
                      </FormLabel>
                      <Input
                        id="dateStart"
                        type="datetime-local"
                        placeholder="Fecha y hora de inicio"
                        size="sm"
                        {...eventRegister("dateStart", {
                          required: "Obligatorio",
                        })}
                        max-width="164.01px"
                      />
                    </FormControl>
                  </Box>

                  <Box width={"49%"}>
                    <FormControl isInvalid={errors.dateEnd}>
                      <FormLabel htmlFor="dateEnd" fontSize="sm">
                        Fecha fin{" "}
                      </FormLabel>
                      <Input
                        id="dateEnd"
                        type="datetime-local"
                        placeholder="Fecha y hora de fin"
                        size="sm"
                        {...eventRegister("dateEnd", {
                          required: "Obligatorio",
                        })}
                      />
                    </FormControl>
                  </Box>
                </HStack>

                <Stack pt={4}>
                  {editEvent.title && (
                    <HStack gap={0}>
                      <FormLabel htmlFor="defaultCoachId" fontSize="sm">
                        Participantes
                      </FormLabel>
                    </HStack>
                  )}

                  <Stack textAlign="left" mt={-2}>
                    {editEvent.title && trainingParticipants === null && (
                      <Spinner size="lg" my={"8px"} />
                    )}
                    {editEvent.title &&
                      trainingParticipants &&
                      trainingParticipants.length === 0 &&
                      (editEvent.extendedProps.visible ? (
                        <Button
                          size="sm"
                          mt={0.5}
                          mb={3.5}
                          colorScheme="blue"
                          onClick={() => setEditingParticipants([])}
                          display={!editingParticipants ? "block" : "none"}
                        >
                          Añadir participantes
                        </Button>
                      ) : (
                        <Text mb={6}>
                          Publica la sesión para añadir participantes
                        </Text>
                      ))}
                    {editEvent.title &&
                      trainingParticipants &&
                      trainingParticipants.length > 0 &&
                      !editingParticipants && (
                        <Button
                          size="xl"
                          variant="ghost"
                          w="fit-content"
                          _hover={{
                            bg: "transparent",
                          }}
                          onClick={() =>
                            setEditingParticipants(trainingParticipants)
                          }
                          title="Añadir o eliminar participantes"
                        >
                          <AvatarGroup max={maxAvatars} textAlign={"right"}>
                            {trainingParticipants.map((participant) => (
                              <Avatar
                                key={participant.id}
                                size={"md"}
                                src={participant.avatar}
                                name={`${participant.name} ${participant.surname1}`.trim()}
                              />
                            ))}
                          </AvatarGroup>
                        </Button>
                      )}

                    {editingParticipants && (
                      <>
                        <Box max-width="336px" position="relative">
                          <Box>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                mt={"-3px"}
                              >
                                <MdOutlineSearch />
                              </InputLeftElement>
                              <InputRightElement mr={0} mt={"-3px"}>
                                <MdClose
                                  cursor="pointer"
                                  onClick={deleteInputValueMobile}
                                />
                              </InputRightElement>
                              <Input
                                id="search-input-mobile"
                                borderBlockEndStyle={"4px yellow solid"}
                                type="text"
                                size="sm"
                                placeholder="Buscar..."
                                onChange={handleType}
                                autoComplete="off"
                                onFocus={() => setInputFocused(true)}
                                onBlur={() =>
                                  setTimeout(() => setInputFocused(false), 100)
                                }
                              />
                            </InputGroup>
                          </Box>

                          <Stack
                            mt={"0.5px"}
                            spacing={0}
                            w="100%"
                            border="1px solid #e2e8f0"
                            maxH="154px"
                            overflow="auto"
                            position="absolute"
                            zIndex={10}
                            bgColor="white"
                            display={isInputFocused ? "flex" : "none"}
                          >
                            {search && search.length > 0 ? (
                              search.map((user) => (
                                <HStack
                                  key={user.id}
                                  id={user.id}
                                  className="prevent-select user-box"
                                  px={2.5}
                                  py={2}
                                  data-user={JSON.stringify(user)}
                                  data-user-selectable={
                                    user.bonoId !== null &&
                                    !editingParticipants.some(
                                      (tp) => tp.id === user.id
                                    )
                                  }
                                  onClick={handleAddParticipant}
                                  cursor={
                                    user.bonoId === null ||
                                    editingParticipants.some(
                                      (tp) => tp.id === user.id
                                    )
                                      ? "cursor"
                                      : "pointer"
                                  }
                                >
                                  <Avatar
                                    size="sm"
                                    src={user.avatar}
                                    name={`${user.name} ${user.surname1}`.trim()}
                                    filter={
                                      user.bonoId === null ||
                                      editingParticipants.some(
                                        (tp) => tp.id === user.id
                                      )
                                        ? "grayscale(100%)"
                                        : "none"
                                    }
                                  />
                                  <Text id="avatar-text" ml={1}>
                                    {user.name + " " + user.surname1}
                                  </Text>
                                  <Spacer />
                                  {!user.activeBonos && (
                                    <Badge
                                      mr={1}
                                      colorScheme="red"
                                      variant="solid"
                                      fontWeight="400"
                                      fontFamily=""
                                    >
                                      SIN BONO
                                    </Badge>
                                  )}
                                  {user.activeBonos &&
                                    !editingParticipants.some(
                                      (tp) => tp.id === user.id
                                    ) && (
                                      <Badge
                                        mr={1}
                                        colorScheme="blue"
                                        variant="solid"
                                        fontWeight="400"
                                        fontFamily=""
                                      >
                                        ACTIVO
                                      </Badge>
                                    )}
                                </HStack>
                              ))
                            ) : (
                              <Text>No se han encontrado resultados</Text>
                            )}
                          </Stack>
                        </Box>

                        {editingParticipants.length !== 0 && (
                          <Stack
                            spacing={3}
                            w="100%"
                            border="1px solid #e2e8f0"
                            px={2.5}
                            py={"11px"}
                            maxH="145px"
                            overflow="auto"
                          >
                            {editingParticipants.map((participant) => (
                              <>
                                <div key={editingParticipants.id}>
                                  <HStack
                                    key={
                                      participant.name +
                                      " " +
                                      participant.surname1
                                    }
                                  >
                                    <Avatar
                                      size="sm"
                                      key={
                                        participant.name +
                                        " " +
                                        participant.surname1
                                      }
                                      name={`${participant.name} ${participant.surname1}`.trim()}
                                      src={participant.avatar}
                                      className="prevent-select"
                                    />
                                    <label>
                                      <Text id="avatar-text" ml={1}>
                                        {participant.name +
                                          " " +
                                          participant.surname1}
                                      </Text>
                                    </label>
                                    <Controller
                                      id={participant.id}
                                      name={`participantes.${participant.id}`}
                                      control={control}
                                      defaultValue={true}
                                      render={({ field }) => (
                                        <Checkbox
                                          defaultChecked
                                          {...field}
                                          ml="auto"
                                          mr="4px"
                                        />
                                      )}
                                    />
                                  </HStack>
                                </div>
                              </>
                            ))}
                          </Stack>
                        )}
                        <Button
                          size="sm"
                          colorScheme="blue"
                          onClick={handleUpdateParticipants}
                          isLoading={isLoading}
                        >
                          Guardar participantes
                        </Button>
                      </>
                    )}
                  </Stack>
                </Stack>
              </ModalBody>
              <ModalFooter pt={3}>
                {editEvent.title && (
                  <Button
                    onClick={handleDelete}
                    colorScheme="red"
                    variant="outline"
                    borderRadius={0}
                    display={"flex"}
                    alt="Eliminar sesión"
                    justifyContent={"flex-start"}
                    isDisabled={isLoading}
                  >
                    {/*Eliminar&nbsp;*/}
                    <MdOutlineEventBusy />
                  </Button>
                )}
                <Spacer />
                <Button
                  onClick={handleClose}
                  borderRadius={0}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alt="Cancelar"
                  mr={2}
                  isDisabled={isLoading}
                  /* Botón de guardado */
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant={"outline"}
                  colorScheme="blue"
                  borderRadius={0}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alt="Guardar sesión"
                  isDisabled={editingParticipants}
                  /* Botón de guardado */
                >
                  Guardar&nbsp;
                  <MdOutlineEventAvailable />
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  }

  // ****** MODAL PUBLISH EVENTS ******
  function ModalPublish({ isOpen, onAction }) {
    const currentDate = new Date();
    const mondays = [];
    const fridays = [];
    currentDate.setHours(0, 0, 0, 0);

    // Obtén el día actual de la semana (0 es domingo, 1 es lunes, ..., 6 es sábado)
    const currentDayOfWeek = currentDate.getDay();

    // Siempre coge el lunes anterior al día en que nos encontramos
    if (currentDayOfWeek > 1) {
      currentDate.setDate(currentDate.getDate() - (currentDayOfWeek - 1));
    }

    // Agrega el primer lunes a la lista
    mondays.push(new Date(currentDate));
    fridays.push(new Date(currentDate.getTime() + 6 * 24 * 60 * 60 * 1000)); // Avanza 4 días para llegar al viernes

    // Continúa agregando lunes hasta alcanzar los 4 meses (16 semanas) en el futuro
    while (mondays.length < 8) {
      currentDate.setDate(currentDate.getDate() + 7); // Avanza 7 días (una semana)
      mondays.push(new Date(currentDate));
      fridays.push(new Date(currentDate.getTime() + 4 * 24 * 60 * 60 * 1000)); // Avanza 4 días para llegar al viernes
    }

    const {
      handleSubmit,
      register: eventRegister,
      formState: { errors },
    } = useForm({
      defaultValues: {
        fromP: mondays[0],
        toP: fridays[0],
      },
      shouldFocusError: false,
    });

    function handleClose() {
      setPublishedEvents(false);
    }

    function onSubmit(values) {
      const fromPDate = new Date(values.fromP);
      const toPDate = new Date(values.toP);

      // Comprobar si la fecha desde es mayor que la fecha hasta
      if (fromPDate > toPDate) {
        document.getElementById("fromP").setAttribute("aria-invalid", true);
        document.getElementById("toP").setAttribute("aria-invalid", true);
        return;
      }
      onAction(values);
    }

    return (
      <>
        <Modal
          onClose={handleClose}
          isOpen={isOpen}
          isCentered
          size="sm"
          borderRadius={"0px"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign="left" mt={2}>
              <Text>Publicación de eventos</Text>
            </ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <HStack>
                  <FormControl isInvalid={errors.fromP}>
                    <FormLabel htmlFor="fromP" fontSize="sm">
                      Fecha desde
                    </FormLabel>
                    <Input
                      type="date"
                      id="fromP"
                      size="sm"
                      placeholder="Elegir fecha desde"
                      {...eventRegister("fromP", {
                        required: "Obligatorio",
                      })}
                    />
                  </FormControl>

                  <FormControl isInvalid={errors.toP}>
                    <FormLabel htmlFor="toP" fontSize="sm">
                      Fecha hasta
                    </FormLabel>
                    <Input
                      type="date"
                      id="toP"
                      size="sm"
                      placeholder="Elegir fecha hasta"
                      {...eventRegister("toP", { required: "Obligatorio" })}
                    />
                    {/*
                    <Select
                      id="toP"
                      placeholder="Elegir fecha hasta"
                      size="sm"
                      {...eventRegister("toP", {
                        required: "Obligatorio",
                      })}
                    >
                      {fridays.map((date) => (
                        <option key={date} value={date}>
                          {date.getDate().toString().padStart(2, "0") +
                            "/" +
                            (date.getMonth() + 1) +
                            "/" +
                            date.getFullYear()}
                        </option>
                      ))}
                      </Select>*/}
                  </FormControl>
                </HStack>
              </ModalBody>

              <ModalFooter>
                <Button
                  onClick={handleClose}
                  borderRadius={0}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  mr={2}
                >
                  Cancelar
                </Button>
                <Spacer />
                <Button
                  type="submit"
                  /*onClick={handleSubmit}*/
                  borderRadius={0}
                  display={"flex"}
                  variant="outline"
                  justifyContent={"flex-end"}
                  colorScheme="blue"
                >
                  Publicar
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  }
}

// Draggable events
const ExternalEvent = memo(({ event }) => {
  let elRef = useRef(null);

  useEffect(() => {
    let draggable = new Draggable(elRef.current, {
      eventData: function () {
        return { ...event, create: true };
      },
    });

    // a cleanup function
    return () => draggable.destroy();
  });

  return (
    <Box
      ref={elRef}
      className="fc-event fc-h-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2"
      title={event.title}
      width={{ base: "150px", sm: "160px" }}
      style={{
        display: "inline-block",
        margin: "1px",
        borderRadius: 0,
        backgroundColor: event.color,
        borderColor: event.color,
        cursor: "pointer",
        height: "30px",
        WebkitUserSelect: "none" /* Safari */,
        msUserSelect: "none" /* IE 10 and IE 11 */,
        userSelect: "none" /* Standard syntax */,
        fontWeight: "400",
      }}
    >
      <div
        className="fc-event-main"
        style={{ margin: "auto", height: "100%", display: "flex" }}
      >
        <Text
          fontWeight={300}
          px={2}
          m="auto"
          fontSize={{ base: "xs", sm: "14px" }}
        >
          {event.title} - {event.defaultCoachName.split(" ").pop()}
        </Text>
      </div>
    </Box>
  );
});
