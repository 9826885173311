import React from "react";
import { Link as ReachLink } from "react-router-dom";
import {
  Avatar,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Link,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useAuth } from "../../../auth/useAuth";
import { MdLock, MdFileDownload } from "react-icons/md";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export function UserMenu({ onAction }) {
  const navigate = useNavigate();
  const auth = useAuth();

  const [installPrompt, setInstallPrompt] = useState(false);

  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      console.log("beforeinstallprompt event fired")
      
      e.preventDefault(); // avoids showing the default browser install prompt
      setInstallPrompt(e); // stores the event for later use
    };

    // listens for the beforeinstallprompt event
    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);

    // removes the event listener when the component is unmounted
    return () => { 
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler
      );
    };
  }, []);

  const handleInstallClick = async () => {
    if (installPrompt) {
      let choiceResult = [];

      try {  
        await installPrompt.prompt(); // shows the prompt
        choiceResult = await installPrompt.userChoice; // waits for the user to respond to the prompt

        if (choiceResult.outcome === "accepted") {
          console.log("El usuario aceptó la instalación");
          Cookies.set("AppView", "true", { expires: 365 });
        } else {
          console.log("El usuario rechazó la instalación");
        }
      } catch (error) {
        console.error("Ocurrió un error durante la instalación:", error);

      } finally {
        
        // hides the custom install prompt and redirects to avoid shwoing the footer
        setInstallPrompt(false);
        if (choiceResult.outcome === "accepted") navigate("/")
      }
    }
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        rounded={"full"}
        variant={"link"}
        cursor={"pointer"}
        style={{ textDecoration: "none" }}
        minW={0}
      >
        <HStack spacing={0.5}>
          <Avatar
            border="2px solid white"
            size="sm"
            name={`${auth.user.name} ${auth.user.surname1}`.trim()}
            src={auth.user.avatar}
            mr={1}
          />
          <ChevronDownIcon color="white" mt={0.5} />
        </HStack>
      </MenuButton>
      <MenuList alignItems={"center"} color="black" borderRadius={0}>
        <Link as={ReachLink} to={"/profile"} style={{ textDecoration: "none" }}>
          <Avatar
            size="xl"
            mt={5}
            mb={3}
            border="2px solid gray"
            name={`${auth.user.name} ${auth.user.surname1}`.trim()}
            src={auth.user.avatar}
            _hover={{
              cursor: "pointer",
              blur: "20px",
            }}
          />
        </Link>
        <Text fontWeight={400}>¡Hola {auth.user.name}!</Text>
        <MenuDivider mt={5} />
        <MenuItem p={0}>
          <Link
            as={ReachLink}
            to={"/profile"}
            style={{ textDecoration: "none" }}
            w="100%"
            h="100%"
            pl={3}
            pr={2}
            py={1.5}
          >
            Perfil
          </Link>
        </MenuItem>

        {(!Cookies.get("AppView") && installPrompt) && (
          <MenuItem p={0} display="flex">
            <Link
              style={{ textDecoration: "none" }}
              w="100%"
              h="100%"
              pl={3}
              pr={2}
              py={1.5}
              display="flex"
              onClick={handleInstallClick}
            >
              Descárgate la app&nbsp;
              <MdFileDownload
                style={{ marginTop: "6px", position: "relative" }}
                size="1em"
              />
            </Link>
          </MenuItem>
        )}
        <MenuItem
          p={0}
          display={
            auth.isLogged() && auth.getRoles()?.includes("COACH_ROLE")
              ? "flex"
              : "none"
          }
        >
          <Link
            as={ReachLink}
            to={"/coach"}
            style={{ textDecoration: "none" }}
            w="100%"
            h="100%"
            pl={3}
            pr={2}
            py={1.5}
            display="flex"
          >
            Entrenador&nbsp;
            <MdLock
              style={{ marginTop: "6px", position: "relative" }}
              size="0.8em"
            />
          </Link>
        </MenuItem>
        <MenuItem
          p={0}
          display={
            auth.isLogged() && auth.getRoles()?.includes("ADMIN_ROLE")
              ? "flex"
              : "none"
          }
        >
          <Link
            as={ReachLink}
            to={"/admin"}
            style={{ textDecoration: "none" }}
            w="100%"
            h="100%"
            pl={3}
            pr={2}
            py={1.5}
            display="flex"
          >
            Administrador&nbsp;
            <MdLock
              style={{ marginTop: "6px", position: "relative" }}
              size="0.8em"
            />
          </Link>
        </MenuItem>
        <MenuItem onClick={onAction}>Cerrar sesión</MenuItem>
      </MenuList>
    </Menu>
  );
}
