import {
  Text,
  Button,
  //  Image,
  Stack,
  AbsoluteCenter,
  Heading,
  Box,
} from "@chakra-ui/react";
import "./style.css";
//import MoveItLogo from "../../images/move_it.svg";
//import HyroxLogo from "../../images/HYROX_GYM_LOGO.png";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
//import XmasDanaHorizontal from "../../images/portrait/xmas_dana_horizontal_white.png";
//import XmasDanaMob from "../../images/portrait/xmas_dana.png";

// NOT USED ANYMORE
// <Image alt="MOVE IT LOGO"src={MoveItLogo} width={{base: "300px", sm: "75vh", md: "50vw", lg: "40vw", xl: "35vw"}} className="title-fade-in"/>

export function Portrait() {
  return (
    <>
      <Swiper
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
          allowTouchMove: true,
        }}
        modules={[Autoplay]}
      >
        {/*<SwiperSlide>
          <Box
            className="slider-section"
            bgImage={{
              base: XmasDanaMob,
              sm: XmasDanaMob,
              md: XmasDanaMob,
              lg: XmasDanaHorizontal,
            }}
            color="white"
          >
            <AbsoluteCenter w={{ base: "300px", sm: "80%" }}>
              <Heading
                className=" title-fade-in"
                fontWeight={400}
                fontFamily="Oswald"
                fontSize={{
                  base: "40px",
                  sm: "40px",
                  md: "60px",
                  lg: "80px",
                }}
                mt={"45px !important"}
                m={{ base: "-20px", sm: "-30px" }}
                display={{
                  base: "block",
                  sm: "block",
                  md: "block",
                  lg: "none",
                }}
              >
                XMAS CHALLANGE
              </Heading>
              <Text
                fontWeight={500}
                fontSize={{ base: "4vw", sm: "4vw", md: "3vw", lg: "2vw" }}
                mt={{ base: "6vw", sm: "4.5vw", md: "4vw", lg: "2.5vw" }}
                className="title-fade-in"
                display={{
                  base: "block",
                  sm: "block",
                  md: "block",
                  lg: "none",
                }}
              >
                21/12/2024
              </Text>
              <Button
                className="slider-section-btn title-fade-in"
                bg={"blue.400"}
                color={"white"}
                mt={{ base: "4vw", sm: "4vw", md: "3vw", lg: "80" }}
                borderRadius={0}
                _hover={{
                  bg: "blue.600",
                }}
                as={"a"}
                href="https://cucfitness.com/xmaschallenge2024.pdf"
                target="_blank" // Abre en una nueva ventana/pestaña
                rel="noopener noreferrer" // Mejora la seguridad
                fontWeight={400}
              >
                ¡Inscríbete!
              </Button>
            </AbsoluteCenter>
          </Box>
        </SwiperSlide>*/}

        <SwiperSlide>
          <Box className="slider-section slide1" color="white">
            <AbsoluteCenter w={{ base: "300px", sm: "80%" }}>
              <Heading
                className="move-it-lettering title-fade-in"
                fontFamily="Oswald"
                fontSize={{
                  base: "85px",
                  sm: "20vw",
                  md: "150px",
                  lg: "150px",
                }}
                mt={"45px !important"}
                m={{ base: "-20px", sm: "-30px" }}
              >
                MOVE IT!
              </Heading>
              <Text
                fontWeight={500}
                fontSize={{ base: "6vw", md: "5vw", lg: "3vw" }}
                mt={{ base: "2vw" }}
                className="title-fade-in"
              >
                EST. 2020
              </Text>
              <Button
                className="slider-section-btn title-fade-in"
                bg={"blue.400"}
                color={"white"}
                //size={{ base: "sm", sm: "md" }}
                mt={{ base: "4vw", sm: "3vw", md: "3vw", lg: "2vw" }}
                borderRadius={0}
                _hover={{
                  bg: "blue.600",
                }}
                fontWeight={400}
              >
                ¡Conócenos!
              </Button>
            </AbsoluteCenter>
          </Box>
        </SwiperSlide>

        <SwiperSlide>
          <Box className="slider-section slide2" color="white">
            <Stack spacing={{ base: 5, sm: 5, md: 5, lg: 6 }}>
              <Heading
                className="title-fade-in"
                fontFamily="Oswald"
                fontWeight="400"
                fontSize={{ base: "8vw", sm: "6vw", md: "6vw", lg: "4vw" }}
                mt={{
                  base: "20vh !important",
                  sm: "19vh !important",
                  md: "18vh !important",
                  lg: "18vh !important",
                  xl: "18vh !important",
                  "2xl": "17vh !important",
                }}
                //m={{ base: "-20px", sm: "-30px" }}
              >
                ¿Has probado nuestra app?
              </Heading>
              <Button
                w="fit-content"
                m="auto"
                mt="0"
                className="slider-section-btn title-fade-in"
                bg={"blue.400"}
                color={"white"}
                //                size={{ base: "sm", sm: "md" }}
                borderRadius={0}
                _hover={{
                  bg: "blue.600",
                }}
                fontWeight={400}
              >
                ¡Descárgala ya!
              </Button>
            </Stack>
          </Box>
        </SwiperSlide>

        {/*<SwiperSlide>
          <Box className="slider-section slide1">
          <AbsoluteCenter w={{ base: "300px", sm: "80%" }}>
              <Heading
                className="title-fade-in"
                fontFamily="Brutal Type"
                letterSpacing="10px"
                color={"white"}
                fontSize={{
                  base: "10vw",
                  sm: "10vw",
                  md: "80px"
                }}
                mt={"45px !important"}
                m={{ base: "-20px", sm: "-30px" }}
              >
                HYROX GYM 
              </Heading>
              <Text
                fontSize={{ base: "6vw", md: "5vw", lg: "4vw" }}
                mt={{ base: "4vw", sm: "3vw", md: "3vw", lg: "2vw" }}
                className="title-fade-in"
                color="#FFED00"
                fontFamily="Brutal Type"
                letterSpacing="10px"
              >
                OFFICIAL
              </Text>
              <Button
                className="slider-section-btn title-fade-in"
                bg="blue.400"
                color={"white"}
                //size={{ base: "sm", sm: "md" }}
                mt={{ base: "4vw", sm: "3vw", md: "3vw", lg: "2vw" }}
                borderRadius={0}
                _hover={{
                  bg: "blue.600",
                }}
                fontWeight={400}
              >
                ¡Prepárate!
              </Button>
            </AbsoluteCenter>
            </Box>
              </SwiperSlide>*/}
      </Swiper>
    </>
  );
}
